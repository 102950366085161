import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiButton } from '@elastic/eui';
import { Link } from 'gatsby';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud",
  "path": "/Web_User_Interface/1080p_Series/Cloud/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Cloud",
  "image": "./WebUI_1080p_SearchThumb_Cloud_Introduction.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Cloud_Introduction.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='INSTAR Cloud' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='IP Camera Cloud Storage' image='/images/Search/QI_SearchThumb_WebUI_1080p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Cloud/' locationFR='/fr/Web_User_Interface/1080p_Series/Cloud/' crumbLabel="INSTAR Cloud" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/085b4e016d5ed15913a7853d342ec8e3/8dd93/1080p_Settings_Cloud.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACDklEQVQoz5WSz2sTQRTH93+THtRqQezFg21SazSbTTeb3Zk3v3Zmk6YVaWmlHjzoQYqXXhQPYmIEibUIil4E8aL4E1qhlmBCs9nNymy0ND3ZD+8wA/N53wfzDIQQTgEAIQQQChgxtTi31rDWmldW6rPLB/W4cPMZXrrrQ1FJpgQYCGMPAQBQSghA7vJsNjM1X1v4/LPzaafzdTf8vhcN69uvcLuTNFsvBS0HPlFSGi6CkqeDtUvZ1ZI7YxYXl1f3u797nXYchck/BoM4SZLW5pbtlpVPlQoMBOBhDIQwSkyPnLXgVK5sVpba3V6nN9jvhVEcR1EYh91eXzd6tfk0e2la+EwxWye76K9cQPRckZzOOYWF1f7ex2TnbRxHSZJst/tvfsS7H74k796/frE147gcLMVdA2NIXQ1jHChzEQ6qtfr9e42NO81G/Xmr9fDRk/UHzfVbtzdWbqxcv3Zx6kJVceUTAwgpuWjoc84BMMbI87zCnF0olgqWxinZgsL5ycmT4+NnJibGxk7MB0wKMAghSP+TThZCSCl931dKVVKClEqlUq1WCSGAMWPM8zylCQxyCM65zkWIcy5SeMpBU9u2HceRUqayGpGHrxljR2TOuVKKEJLP503TBIAgCEbk4YYFh9CTjZ5lyvB6NJlzLo+DlvVipslSytpxMDyEy+lyU0oty8pms5lMZvr/+AMRlsO6mmlzbgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/085b4e016d5ed15913a7853d342ec8e3/e4706/1080p_Settings_Cloud.avif 230w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/d1af7/1080p_Settings_Cloud.avif 460w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/7f308/1080p_Settings_Cloud.avif 920w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/19805/1080p_Settings_Cloud.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/085b4e016d5ed15913a7853d342ec8e3/a0b58/1080p_Settings_Cloud.webp 230w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/bc10c/1080p_Settings_Cloud.webp 460w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/966d8/1080p_Settings_Cloud.webp 920w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/61eb3/1080p_Settings_Cloud.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/085b4e016d5ed15913a7853d342ec8e3/81c8e/1080p_Settings_Cloud.png 230w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/08a84/1080p_Settings_Cloud.png 460w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/c0255/1080p_Settings_Cloud.png 920w", "/en/static/085b4e016d5ed15913a7853d342ec8e3/8dd93/1080p_Settings_Cloud.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/085b4e016d5ed15913a7853d342ec8e3/c0255/1080p_Settings_Cloud.png",
              "alt": "Web User Interface - 1080p Series - Cloud",
              "title": "Web User Interface - 1080p Series - Cloud",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Link to="/Motion_Detection/INSTAR_Cloud/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Read More</EuiButton></Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      